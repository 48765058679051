import { create } from "zustand";
// import { devtools } from "zustand/middleware";
import { persist, createJSONStorage, devtools } from "zustand/middleware";
import produce from "immer";

import type { RouterOutputs } from "../utils/api";
import type { RedisEvent } from "../types/redis/events";
import { keyBy } from "../utils/keyBy";
import type { Alert } from "../types/redis/alerts";
import { mergeSimilarAlerts } from "../utils/alerts";
import { type Messaging } from "firebase/messaging";

export type TabKey =
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "searchResults"
  | "eventIds"; // We set this tabKey when we want to load events by eventIds

export type OddsFormat = "decimal" | "american";
export type DevigMethod = "power" | "additive" | "multiplicative" | "shin";

export type SelectedRow = {
  eventId: number;
  outcome: string;
  period: number;
  rowOutcome: string | undefined;
  lineType: "spreads" | "totals" | "team_totals" | "moneyline" | undefined;
  points: string | undefined;
};
interface EventsState {
  events: { [eventId: string]: RedisEvent };
  alerts: { [alertId: string]: Alert };
  updateSelectedExtEventIdAndOutcome: (obj: {
    ext_event_id: number;
    outcome: string;
  }) => void;
  selectedExtEventAndOutcome: { ext_event_id: number; outcome: string } | null;
  // updateEvents: (events: MatchEvent[]) => void;
  setSearchedEvent: (event: RedisEvent) => void;
  setStarredEvents: (events: RedisEvent[]) => void;
  setEventsForEventIds: (events: RedisEvent[]) => void;
  setEvents: (
    events: RouterOutputs["events"]["getPaginatedEvents"]["results"]
  ) => void;
  setAlerts: (
    alerts: Alert[],
    alertingCriteriaIdsWithPushNotificationsEnabled: string[]
  ) => void;
  updateEvents: (
    events: RouterOutputs["events"]["getUpdatedEvents"]["results"]["documents"]
  ) => void;
  activeTab: TabKey;
  setActiveTab: (tabKey: TabKey) => void;
  showAltLines: boolean;
  toggleShowAltLines: () => void;
  showLiveEvents: boolean;
  toggleShowLiveEvents: () => void;
  muteAllAlertTypes: () => void;
  unMuteAllAlertTypes: () => void;
  areDroppingOddsAlertsMuted: boolean;
  toggleMuteDroppingOddsAlerts: () => void;
  areLimitChangeAlertsMuted: boolean;
  toggleMuteLimitChangeAlerts: () => void;
  areOpeningLineAlertsMuted: boolean;
  toggleMuteOpeningLineAlerts: () => void;
  selectedRow: SelectedRow | undefined;
  setSelectedRow: (selectedRow: SelectedRow) => void;
  oddsFormat: OddsFormat;
  setOddsFormat: (oddsFormat: OddsFormat) => void;
  devigMethod: DevigMethod;
  setDevigMethod: (devigMethod: DevigMethod) => void;
  alertsPaused: boolean;
  setAlertsPaused: (alertsPaused: boolean) => void;
  clearEvents: () => void;
  rewardfulReferral: string | null;
  setRewardfulReferral: (referral: string | null) => void;
  sidekickLineVisibility: {
    price: boolean;
    limit: boolean;
  };
  toggleSidekickLineVisibility: (dataKey: "price" | "limit") => void;
  isConfigureModalOpen: boolean;
  setIsConfigureModalOpen: (isOpen: boolean) => void;
  isConfigureAlertsDrawerOpen: boolean;
  setIsConfigureAlertsDrawerOpen: (isOpen: boolean) => void;
  isStandalonePWA: boolean;
  setIsStandalonePWA: (isStandalonePWA: boolean) => void;
  fcmToken: string | null;
  setFCMToken: (fcmToken: string | null) => void;
  isFCMTokenLoading: boolean;
  setIsFCMTokenLoading: (isFCMTokenLoading: boolean) => void;
  firebaseMessaging: Messaging | null;
  setFirebaseMessaging: (firebaseMessaging: Messaging | null) => void;
  notificationPermission: "default" | "granted" | "denied" | null;
  setNotificationPermission: (
    notificationPermission: "default" | "granted" | "denied" | null
  ) => void;
  desktopMainTab:
    | "events"
    | "alerts:droppingOdds"
    | "alerts:openingLine"
    | "alerts:limitChange"
    | "betLog";
  setDesktopMainTab: (
    desktopMainTab:
      | "events"
      | "alerts:droppingOdds"
      | "alerts:openingLine"
      | "alerts:limitChange"
      | "betLog"
  ) => void;
  mobileAlertsTabSubTab:
    | "alerts:droppingOdds"
    | "alerts:openingLine"
    | "alerts:limitChange";
  setMobileAlertsTabSubTab: (
    mobileAlertsTabSubTab:
      | "alerts:droppingOdds"
      | "alerts:openingLine"
      | "alerts:limitChange"
  ) => void;
}

/**
 * 1. Store will be populated by initial request to server
 * and will take the same structure as what is received from BE.
 * This shape should be optimized for ease of handling future updates
 *
 * 2. Table will load store and pass through transformer to structure
 * data for table
 *
 */

export const useEventsStore = create<EventsState>()(
  persist(
    devtools((set) => ({
      events: {},
      alerts: {},
      sidekickLineVisibility: {
        // as boolean is a workaround for a weirdness in TypeScript: https://github.com/pmndrs/zustand/discussions/2068
        price: true as boolean,
        limit: true as boolean,
      },
      toggleSidekickLineVisibility: (dataKey: "price" | "limit") =>
        set((state) => ({
          sidekickLineVisibility: {
            ...state.sidekickLineVisibility,
            [dataKey]: !state.sidekickLineVisibility[dataKey],
          },
        })),
      selectedExtEventAndOutcome: null,
      updateSelectedExtEventIdAndOutcome: (obj: {
        ext_event_id: number;
        outcome: string;
      }) => set((state) => ({ selectedExtEventAndOutcome: obj })),
      setSearchedEvent: (event: RedisEvent) =>
        set((state) => {
          return { events: { [event.event_id]: event } };
        }),
      setStarredEvents: (events: RedisEvent[]) =>
        set((state) => {
          const keyedEvents = keyBy(events, "event_id");
          return { events: keyedEvents };
        }),
      setEventsForEventIds: (events: RedisEvent[]) =>
        set((state) => {
          const keyedEvents = keyBy(events, "event_id");
          return { events: keyedEvents };
        }),
      setEvents: (
        events: RouterOutputs["events"]["getPaginatedEvents"]["results"]
      ) =>
        set((state) => {
          const eventsWithUpdates = {
            ...state.events,
          };
          events.forEach(
            (event) => (eventsWithUpdates[event.event_id] = event)
          );
          return { events: eventsWithUpdates };
        }),
      clearEvents: () => set(() => ({ events: {} })),
      setAlerts: (
        alerts: Alert[],
        alertingCriteriaIdsWithPushNotificationsEnabled: string[]
      ) =>
        set((state) => {
          const mergedAlerts = mergeSimilarAlerts(
            [...Object.values(state.alerts ?? {}), ...alerts],
            alertingCriteriaIdsWithPushNotificationsEnabled
          );

          /**
           * Key alerts by id, so we can easily update them
           */
          const alertsWithUpdates: { [alertId: string]: Alert } = {};
          mergedAlerts.forEach(
            (alert) => (alertsWithUpdates[alert.id] = alert)
          );

          return { alerts: alertsWithUpdates };
        }),
      updateEvents: (
        events: RouterOutputs["events"]["getUpdatedEvents"]["results"]["documents"]
      ) =>
        set((state) => {
          const eventsWithUpdates = {
            ...state.events,
          };
          events.forEach(
            (event) => (eventsWithUpdates[event.value.event_id] = event.value)
          );
          return { events: eventsWithUpdates };
        }),
      setSelectedRow: (selectedRow) => set(() => ({ selectedRow })),
      selectedRow: undefined,
      activeTab: "0",
      setActiveTab: (tabKey: TabKey) =>
        set((state) => {
          if (state.activeTab === tabKey) return state;
          return { events: {}, activeTab: tabKey };
        }),
      alertsPaused: false,
      setAlertsPaused: (alertsPaused: boolean) => set(() => ({ alertsPaused })),
      showAltLines: false,
      toggleShowAltLines: () =>
        set((state) => ({ showAltLines: !state.showAltLines })),
      showLiveEvents: false,
      toggleShowLiveEvents: () =>
        set((state) => ({ showLiveEvents: !state.showLiveEvents })),
      muteAllAlertTypes: () =>
        set(() => ({
          areDroppingOddsAlertsMuted: true,
          areLimitChangeAlertsMuted: true,
          areOpeningLineAlertsMuted: true,
        })),
      unMuteAllAlertTypes: () =>
        set(() => ({
          areDroppingOddsAlertsMuted: false,
          areLimitChangeAlertsMuted: false,
          areOpeningLineAlertsMuted: false,
        })),
      areDroppingOddsAlertsMuted: false,
      toggleMuteDroppingOddsAlerts: () =>
        set((state) => ({
          areDroppingOddsAlertsMuted: !state.areDroppingOddsAlertsMuted,
        })),
      areLimitChangeAlertsMuted: false,
      toggleMuteLimitChangeAlerts: () =>
        set((state) => ({
          areLimitChangeAlertsMuted: !state.areLimitChangeAlertsMuted,
        })),
      areOpeningLineAlertsMuted: false,
      toggleMuteOpeningLineAlerts: () =>
        set((state) => ({
          areOpeningLineAlertsMuted: !state.areOpeningLineAlertsMuted,
        })),
      oddsFormat: "decimal",
      setOddsFormat: (oddsFormat: OddsFormat) => set(() => ({ oddsFormat })),
      devigMethod: "power",
      setDevigMethod: (devigMethod: DevigMethod) =>
        set(() => ({ devigMethod })),
      rewardfulReferral: null,
      setRewardfulReferral: (referral: string | null) =>
        set(() => ({ rewardfulReferral: referral })),
      isConfigureModalOpen: false,
      setIsConfigureModalOpen: (isOpen: boolean) =>
        set(() => ({ isConfigureModalOpen: isOpen })),
      isConfigureAlertsDrawerOpen: false,
      setIsConfigureAlertsDrawerOpen: (isOpen: boolean) =>
        set(() => ({ isConfigureAlertsDrawerOpen: isOpen })),
      isStandalonePWA: false,
      setIsStandalonePWA: (isStandalonePWA: boolean) =>
        set(() => ({ isStandalonePWA })),
      fcmToken: null,
      setFCMToken: (fcmToken: string | null) => set(() => ({ fcmToken })),
      isFCMTokenLoading: false,
      setIsFCMTokenLoading: (isFCMTokenLoading: boolean) =>
        set(() => ({ isFCMTokenLoading })),
      firebaseMessaging: null,
      setFirebaseMessaging: (firebaseMessaging: Messaging | null) =>
        set(() => ({ firebaseMessaging })),
      notificationPermission: null,
      setNotificationPermission: (
        notificationPermission: "default" | "granted" | "denied" | null
      ) => set(() => ({ notificationPermission })),
      desktopMainTab: "alerts:droppingOdds",
      setDesktopMainTab: (
        desktopMainTab:
          | "events"
          | "alerts:droppingOdds"
          | "alerts:openingLine"
          | "alerts:limitChange"
          | "betLog"
      ) => set(() => ({ desktopMainTab })),
      mobileAlertsTabSubTab: "alerts:droppingOdds",
      setMobileAlertsTabSubTab: (
        mobileAlertsTabSubTab:
          | "alerts:droppingOdds"
          | "alerts:openingLine"
          | "alerts:limitChange"
      ) => set(() => ({ mobileAlertsTabSubTab })),
    })),
    {
      name: "user-settings", // name of the item in the storage (must be unique),
      partialize: (state) => ({
        oddsFormat: state.oddsFormat,
        devigMethod: state.devigMethod,
        sidekickLineVisibility: state.sidekickLineVisibility,
      }),
    }
  )
);
