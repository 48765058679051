import { useUser } from "@clerk/nextjs";
import { useEffect, useRef } from "react";
import { api } from "../../utils/api";
import { env } from "../../env.mjs";

interface IPStackResponse {
  ip: string;
  continent_code: string;
  continent_name: string;
  country_code2: string;
  country_code3: string;
  country_name: string;
  country_name_official: string;
  country_capital: string;
  state_prov: string;
  state_code: string;
  district: string;
  city: string;
  zipcode: string;
  latitude: string;
  longitude: string;
  is_eu: boolean;
  calling_code: string;
  country_tld: string;
  languages: string;
  country_flag: string;
  geoname_id: string;
  isp: string;
  connection_type: string;
  organization: string;
  country_emoji: string;
  asn: string;
  currency: {
    code: string;
    name: string;
    symbol: string;
  };
  time_zone: {
    name: string;
    offset: number;
    offset_with_dst: number;
    current_time: string;
    current_time_unix: number;
    is_dst: boolean;
    dst_savings: number;
    dst_exists: boolean;
    dst_start: {
      utc_time: string;
      duration: string;
      gap: boolean;
      dateTimeAfter: string;
      dateTimeBefore: string;
      overlap: boolean;
    };
    dst_end: {
      utc_time: string;
      duration: string;
      gap: boolean;
      dateTimeAfter: string;
      dateTimeBefore: string;
      overlap: boolean;
    };
  };
}
/**
 * 
    "ip": "90.199.59.153",
    "continent_code": "EU",
    "continent_name": "Europe",
    "country_code2": "GB",
    "country_code3": "GBR",
    "country_name": "United Kingdom",
    "country_name_official": "United Kingdom of Great Britain and Northern Ireland",
    "country_capital": "London",
    "state_prov": "England",
    "state_code": "GB-ENG",
    "district": "Greater London",
    "city": "London",
    "zipcode": "E1 6SA",
    "latitude": "51.52747",
    "longitude": "-0.05551",
    "is_eu": false,
    "calling_code": "+44",
    "country_tld": ".uk",
    "languages": "en-GB,cy-GB,gd",
    "country_flag": "https://ipgeolocation.io/static/flags/gb_64.png",
    "geoname_id": "6954642",
    "isp": "Sky Broadband",
    "connection_type": "",
    "organization": "Sky UK Limited",
    "country_emoji": "🇬🇧",
    "asn": "AS5607",
    "currency": {
        "code": "GBP",
        "name": "Pound Sterling",
        "symbol": "£"
    },
    "time_zone": {
        "name": "Europe/London",
        "offset": 0,
        "offset_with_dst": 0,
        "current_time": "2025-03-28 11:44:25.468+0000",
        "current_time_unix": 1743162265.468,
        "is_dst": false,
        "dst_savings": 0,
        "dst_exists": true,
        "dst_start": {
            "utc_time": "2025-03-30 TIME 01",
            "duration": "+1H",
            "gap": true,
            "dateTimeAfter": "2025-03-30 TIME 02",
            "dateTimeBefore": "2025-03-30 TIME 01",
            "overlap": false
        },
        "dst_end": {
            "utc_time": "2025-10-26 TIME 01",
            "duration": "-1H",
            "gap": false,
            "dateTimeAfter": "2025-10-26 TIME 01",
            "dateTimeBefore": "2025-10-26 TIME 02",
            "overlap": true
        }
    }
}
 */

function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift() || null;
  return null;
}

export function UserMetadataHandler() {
  const { user } = useUser();
  const initializeMetadataMutation =
    api.userMetadata.initializeUserMetadata.useMutation();
  const hasAttemptedFetch = useRef(false);

  useEffect(() => {
    const initializeUserMetadata = async () => {
      // Check if we've already attempted a fetch
      if (hasAttemptedFetch.current || !user) return;

      // Get dubClickId from cookie if it exists
      const dubId = getCookie("dub_id");

      // If we have all metadata and no dubId to process, skip
      if (user.publicMetadata.country && !dubId) return;

      // Mark that we've attempted the fetch
      hasAttemptedFetch.current = true;

      try {
        // If we need country data, fetch it
        if (!user.publicMetadata.country) {
          const response = await fetch(
            `https://api.ipgeolocation.io/ipgeo?apiKey=${env.NEXT_PUBLIC_IPGEOLOCATION_API_KEY}`
          );
          const data = (await response.json()) as IPStackResponse;

          console.log("🌍", data);

          if (data.country_name) {
            await initializeMetadataMutation.mutateAsync({
              country: data.country_name,
              countryCode: data.country_code2,
              currencyCode: data.currency.code,
              timezone: data.time_zone.name,
              timezoneOffset: data.time_zone.offset,
              dubClickId: dubId || undefined,
            });
          }
        } else if (dubId && !user.publicMetadata.dubClickId) {
          // If we only need to process dubClickId
          await initializeMetadataMutation.mutateAsync({
            dubClickId: dubId,
          });
        }

        // Reload the user to get the updated metadata
        await user.reload();

        // Clean up dub cookie if it was used
        if (dubId) {
          document.cookie =
            "dub_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
      } catch (e) {
        console.error("Failed to fetch/update user metadata", e);

        // If geolocation failed but we have a dubId, still try to process it
        if (dubId && !user.publicMetadata.dubClickId) {
          try {
            await initializeMetadataMutation.mutateAsync({
              dubClickId: dubId,
            });

            await user.reload();
            document.cookie =
              "dub_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          } catch (dubError) {
            console.error(
              "Failed to process dubClickId after geolocation failure",
              dubError
            );
          }
        }
      }
    };

    initializeUserMetadata().catch(console.error);
  }, [user, initializeMetadataMutation]);

  return null;
}
